import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { colors } from "./globalStyle"
import Scrollspy from "react-scrollspy"
import Scroll from "./scroll"
import logo from "../images/logoNunkki.png"
import styled, { keyframes } from "styled-components"
//import "./burger.css"

const appear = keyframes`
  from {
    opacity: 0.9; 
  }

  to {
    opacity: 1;
  }
`

const MainHeader = styled.header`
  z-index: 9999;
  height: 4.2rem;
  position: sticky;
  font-family: Montserrat;
  letter-spacing: 0.035rem;
  display: flex;
  top: 0;
  justify-content: space-between;
  animation: ${appear} 2s linear;
  background-color: rgba(255, 255, 255, 1);

  nav ul {
    display: flex;
    flex-direction: row;
    jusify-content: space-between;
    list-style-type: none;
    margin: 0;

    li {
      padding: 1.5rem 1.2rem;
      margin: 0;
      cursor: pointer;

      button {
        font-size: 0.7rem;
        text-decoration: none;
        color: ${colors.black};
        cursor: pointer;
        background: none;
        border: 0;
   
      }

      &:hover {
        button {
          opacity: .7;
          //color: ${colors.classicGrey};
        }
      }
    }

    &:last-child {
      padding-right: 1.5em;
    }
  }

  nav:first-child img {
    padding: 1.5rem;
    padding-left: 3rem;
    margin: 0;
    object-fit: cover;
    max-width: 9.5rem;
  }
  nav {
    button{
      background: none;
      border: 0;
      cursor: pointer;
    }
  }
`

const SmallNavigation = styled.nav`
  max-height: 100vh;
  .hidden {
    display: none;
  }

  div.burger {
    height: 2rem;
    width: 2rem;
    position: absolute;
    top: 0.5rem;
    right: 1.5rem;
    cursor: pointer;
  }
  div.x,
  div.y,
  div.z {
    position: absolute;
    margin: auto;
    top: 0px;
    bottom: 0px;
    background: #16b1ff;
    border-radius: 2px;
    -webkit-transition: all 200ms ease-out;
    -moz-transition: all 200ms ease-out;
    -ms-transition: all 200ms ease-out;
    -o-transition: all 200ms ease-out;
    transition: all 200ms ease-out;
  }
  div.x,
  div.y,
  div.z {
    height: 3px;
    width: 26px;
  }
  div.y {
    top: 18px;
  }
  div.z {
    top: 37px;
  }
  div.collapse {
    top: 20px;
    background: #16b1ff;
    -webkit-transition: all 70ms ease-out;
    -moz-transition: all 70ms ease-out;
    -ms-transition: all 70ms ease-out;
    -o-transition: all 70ms ease-out;
    transition: all 70ms ease-out;
  }

  div.rotate30 {
    -ms-transform: rotate(30deg);
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
    -webkit-transition: all 50ms ease-out;
    -moz-transition: all 50ms ease-out;
    -ms-transition: all 50ms ease-out;
    -o-transition: all 50ms ease-out;
    transition: all 50ms ease-out;
  }
  div.rotate150 {
    -ms-transform: rotate(150deg);
    -webkit-transform: rotate(150deg);
    transform: rotate(150deg);
    -webkit-transition: all 50ms ease-out;
    -moz-transition: all 50ms ease-out;
    -ms-transition: all 50ms ease-out;
    -o-transition: all 50ms ease-out;
    transition: all 50ms ease-out;
  }

  div.rotate45 {
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 100ms ease-out;
    -moz-transition: all 100ms ease-out;
    -ms-transition: all 100ms ease-out;
    -o-transition: all 100ms ease-out;
    transition: all 100ms ease-out;
  }
  div.rotate135 {
    -ms-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    -webkit-transition: all 100ms ease-out;
    -moz-transition: all 100ms ease-out;
    -ms-transition: all 100ms ease-out;
    -o-transition: all 100ms ease-out;
    transition: all 100ms ease-out;
  }

  div.circle {
    border-radius: 50%;
    width: 0px;
    height: 0px;
    position: absolute;
    top: 0.5rem;
    right: 1.5rem;
    background: #fff;
    opacity: 1;
    -webkit-transition: all 300ms cubic-bezier(0, 0.995, 0.99, 1);
    -moz-transition: all 300ms cubic-bezier(0, 0.995, 0.99, 1);
    -ms-transition: all 300ms cubic-bezier(0, 0.995, 0.99, 1);
    -o-transition: all 300ms cubic-bezier(0, 0.995, 0.99, 1);
    transition: all 300ms cubic-bezier(0, 0.995, 0.99, 1);
  }
  div.circle.expand {
    width: 50vw;
    height: 50vh;
    top: -2rem;
    right: 2rem;
    -webkit-transition: all 400ms cubic-bezier(0, 0.995, 0.99, 1);
    -moz-transition: all 400ms cubic-bezier(0, 0.995, 0.99, 1);
    -ms-transition: all 400ms cubic-bezier(0, 0.995, 0.99, 1);
    -o-transition: all 400ms cubic-bezier(0, 0.995, 0.99, 1);
    transition: all 400ms cubic-bezier(0, 0.995, 0.99, 1);
  }
  div.menu {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
  }

  div.menu ul li {
    list-style: none;
    position: absolute;
    top: 15rem;
    right: 0;
    opacity: 0;
    width: 100vw;
    text-align: center;
    font-size: 0px;
    -webkit-transition: all 70ms cubic-bezier(0, 0.995, 0.99, 1);
    -moz-transition: all 70ms cubic-bezier(0, 0.995, 0.99, 1);
    -ms-transition: all 70ms cubic-bezier(0, 0.995, 0.99, 1);
    -o-transition: all 70ms cubic-bezier(0, 0.995, 0.99, 1);
    transition: all 70ms cubic-bezier(0, 0.995, 0.99, 1);
  }
  div.menu ul li a {
    color: #16b1ff !important;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.2rem;
  }

  div.menu li.animate {
    font-size: 21px;
    opacity: 1;
    -webkit-transition: all 150ms cubic-bezier(0, 0.995, 0.99, 1);
    -moz-transition: all 150ms cubic-bezier(0, 0.995, 0.99, 1);
    -ms-transition: all 150ms cubic-bezier(0, 0.995, 0.99, 1);
    -o-transition: all 150ms cubic-bezier(0, 0.995, 0.99, 1);
    transition: all 150ms cubic-bezier(0, 0.995, 0.99, 1);
  }
  div.menu li.animate:nth-of-type(1) {
    top: 120px;
    transition-delay: 0s;
  }
  div.menu li.animate:nth-of-type(2) {
    top: 190px;
    transition-delay: 0.03s;
  }
  div.menu li.animate:nth-of-type(3) {
    top: 260px;
    transition-delay: 0.06s;
  }
  div.menu li.animate:nth-of-type(4) {
    top: 330px;
    transition-delay: 0.09s;
  }
  div.menu li.animate:nth-of-type(5) {
    top: 400px;
    transition-delay: 0.12s;
  }
  div.menu li.animate:nth-of-type(6) {
    top: 470px;
    transition-delay: 0.15s;
  }
`

const MainNavigation = styled.nav``

const MainNav = () => (
  <MainNavigation>
    <Scrollspy items={["main"]} currentClassName="is-active" offset={-300}>
      <li>
        <Scroll type="id" element="inspirations">
          <button>inspirations</button>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="services">
          <button>services</button>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="who we are">
          <button>who we are</button>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="contact">
          <button>contact</button>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="say hello">
          <button>say hello</button>
        </Scroll>
      </li>
    </Scrollspy>
  </MainNavigation>
)
//remove href value for anchor
const Header = ({ siteTitle }) => {
  const [menuClosed, setMenuClosed] = useState(true)
  const [classLine, setclassLine] = useState({
    x: "x",
    y: "y",
    z: "z",
  })

  const [width, setWidth] = useState(0)

  useEffect(() => {
    updateWindowDimensions()
    window.addEventListener("resize", updateWindowDimensions)

    return () => {
      window.removeEventListener("resize", updateWindowDimensions)
    }
  })

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth)
  }

  const changeState = () => {
    setMenuClosed(!menuClosed)

    if (menuClosed) {
      setclassLine({
        ...classLine,
        x: "x collapse",
        y: "y collapse",
        z: "z collapse",
      })

      setTimeout(() => {
        setclassLine({
          ...classLine,
          x: "x collapse rotate30",
          y: "hidden",
          z: "z collapse rotate150",
        })
      }, 70)

      setTimeout(() => {
        setclassLine({
          ...classLine,
          y: "hidden",
          x: "x collapse rotate30 rotate45",
          z: "z collapse rotate150 rotate135",
        })
      }, 120)
    } else {
      setclassLine({
        ...classLine,
        x: "x collapse rotate30",
        z: "z collapse rotate150",
      })

      setTimeout(() => {
        setclassLine({ ...classLine, x: "x collapse ", z: "z collapse " })
      }, 50)

      setTimeout(() => {
        setclassLine({ ...classLine, x: "x", z: "z", y: "y" })
      }, 70)
    }
  }
  const SmallNav = () => (
    <SmallNavigation>
      {!menuClosed && (
        <div className={menuClosed ? "circle" : "circle expand"} />
      )}
      {!menuClosed && (
        <div
          className="menu"
          style={!menuClosed ? { background: "white" } : null}
        >
          <ul>
            <li
              onClick={changeState}
              className={menuClosed ? "small-menu" : "animate"}
            >
              <Scroll type="id" element="inspirations">
                <button>inspirations</button>
              </Scroll>
            </li>
            <li
              onClick={changeState}
              className={menuClosed ? "small-menu" : "animate"}
            >
              <Scroll type="id" element="services">
                <button>services</button>
              </Scroll>
            </li>
            <li
              onClick={changeState}
              className={menuClosed ? "small-menu" : "animate"}
            >
              <Scroll type="id" element="who we are">
                <button>who we are</button>
              </Scroll>
            </li>
            <li
              onClick={changeState}
              className={menuClosed ? "small-menu" : "animate"}
            >
              <Scroll type="id" element="contact">
                <button>contact</button>
              </Scroll>
            </li>
            <li
              onClick={changeState}
              className={menuClosed ? "small-menu" : "animate"}
            >
              <Scroll type="id" element="say hello">
                <button>say hello</button>
              </Scroll>
            </li>
          </ul>
        </div>
      )}
      <div
        className={menuClosed ? "burger" : "burger open"}
        onClick={changeState}
      >
        <div className={classLine.x} />
        <div className={classLine.y} />
        <div className={classLine.z} />
      </div>{" "}
    </SmallNavigation>
  )

  return (
    <MainHeader>
      <nav>
        <Scroll type="id" element="banner">
          <button>
            <img src={logo} />
          </button>
        </Scroll>
      </nav>

      {width >= 900 && <MainNav />}
      {width < 900 && <SmallNav />}
    </MainHeader>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
